import React from 'react';
import './App.css';

// This should be http://localhost:9090 for dev.
var endpoint = ''

class IncorrectAnswer extends React.Component {
  render() {
    return <div id="incorrect-answer">
        <div id="correct-answer"><span>{this.props.answer}</span> {this.props.word}</div>
        <div id="next" className="button" onClick={_ => this.props.continue()}>Next</div>
      </div>
  }
}

function Answer(value, clicked) {
    return <div className="answer button" onClick={_ => clicked(value)}>{value}</div>
}

class Result extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return <div>
    <div id="result">Score: {this.props.points} / {this.props.max}</div>
    <div className="button" onClick={_ => this.props.reset('start')}>Try again</div>
    <div className="button" onClick={_ => this.props.reset('exit')}>Main page</div>
    </div>
  }
}

class AdjustableDiv extends React.Component {
  constructor(props) {
    super(props)
    this.state = {small: this.isSmall()}

    this.onWindowResize = this.onWindowResize.bind(this)
    this.classes = this.classes.bind(this)
  }
  onWindowResize() {
    this.setState({small: this.isSmall()})
  }
  isSmall() {
    return window.innerWidth < 800
  }
  componentDidMount() {
    window.addEventListener('resize', this.onWindowResize);
  }
  classes() {
    var c = [this.props.baseClass];

    if (this.state.small) {
      c.push(this.props.smallClass);
    } else {
      c.push(this.props.largeClass)
    }

    return c.join(' ')
  }
  render() {
    const { children } = this.props;

    return <div className={this.classes()}>{children}</div>
  }
}



function Fetch(query, req) {
  return fetch(query, {
    method: 'post',
    body: JSON.stringify(req)
  }).then(data => data.json()).catch(e => console.log(e))
}

class MultipleChoice extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      question: 0,
      points: 0,
      answered: false,
      test: [],
    }
    this.onClick = this.onClick.bind(this)
    this.continue = this.continue.bind(this)
    this.question = this.question.bind(this)
    this.correctAnswer = this.correctAnswer.bind(this)
    this.reset = this.reset.bind(this)
  }
  componentDidMount() {
  }
  reset(command) {
    if (command == 'start') {
      Fetch(endpoint+'/quiz', {}).then(test => this.setState({
        question: 0,
        points: 0,
        answered: false,
        test: test.Words,
      }))
    } else if (command == 'exit') {
      this.setState({test: []})
    }
  }
  onClick(k) {
    if (this.correctAnswer() == k) {
      this.setState({
        points: this.state.points + 1,
      })
      this.continue()
    } else {
      this.setState({
        answered: true,
      })
    }
  }
  continue() {
    this.setState({
      answered: false,
      question: this.state.question + 1,
    })

    if (this.state.question + 1 == this.state.test.length) {
      Fetch(endpoint+'/score', {S: this.state.points, O: this.state.test.length})
    }
  }
  question() {
    var answers = [];
    this.props.answers.forEach((v, k) => {
      answers.push(Answer(v, this.onClick, this.state.answer))
    })
    return <div>
      <div>{this.word()}</div>
      {answers}
    </div>

  }
  word() {
    return this.state.test[this.state.question].N
  }
  answer() {
    return <IncorrectAnswer answer={this.correctAnswer()} word={this.word()} continue={this.continue}/>
  }
  correctAnswer() {
    return this.state.test[this.state.question].A
  }
  begin() {
    return <div id="return">
        <div className="feature">
          <AdjustableDiv baseClass="main" smallClass="main-small" largeClass="main-large">
            <div className='verical-align'>
              <div>Take a quiz now!</div>
              <div className="button" onClick={_ => this.reset('start')}>Begin!</div>
            </div>
          </AdjustableDiv>
          <AdjustableDiv baseClass="description" smallClass="main-small" largeClass="main-large">
            <div>
              Der. Die. Das. Take a quiz and check your knowledge of German articles!
              <p className="more-words">Now with more words!</p>
            </div>
          </AdjustableDiv>
        </div>
        <div className="feature">
          <AdjustableDiv baseClass="description" smallClass="main-small" largeClass="main-large">
            Use our interactive sentence builder to explore the caveats of German grammar.
          </AdjustableDiv>
          <AdjustableDiv baseClass="main" smallClass="main-small" largeClass="main-large">
            <div>
              <div className="SB-description">
                <div>Sentence builder</div>
                <div className="coming-soon">Coming soon!</div>
              </div>
              <div className="sentenceBuilder">
                <div className="SB-Subject">Das Haus</div>
                <div className="SB-Verb">ist</div>
                <div className="SB-Object">groß</div>
              </div>
            </div>
          </AdjustableDiv>
        </div>
        <div className="feature">
        <AdjustableDiv baseClass="main" smallClass="main-small" largeClass="main-large">
            <div>Verbs</div>
            <div className="coming-soon">Coming soon!</div>
          </AdjustableDiv>
          <AdjustableDiv baseClass="description" smallClass="main-small" largeClass="main-large">
            Present? Past. Future! Conquer all of them!
          </AdjustableDiv>
        </div>
        <div className="feature">
          <AdjustableDiv baseClass="description" smallClass="main-small" largeClass="main-large">
            Der? Oder den? Vielleicht dem! Which one then?
          </AdjustableDiv>
          <AdjustableDiv baseClass="main" smallClass="main-small" largeClass="main-large">
            <div>Nouns</div>
            <div className="coming-soon">Coming soon!</div>
          </AdjustableDiv> 
        </div> 
      </div>
  }
  route() {
    if (this.state.test === undefined || this.state.test.length == 0) {
      return this.begin()
    }
    if (this.state.question == this.state.test.length) {
      return <Result points={this.state.points} max={this.state.test.length} reset={this.reset}/>
    }
    if (this.state.answered) {
      return this.answer()
    } 
    
    return this.question()
  }
  render() {
    return <div id="multipleChoice">
      <div id="question">
        { this.route() }
      </div>
    </div>
  }
}

function App() {
  return (
    <div className="quiz">
      <header className="header">
        <span className="de">einfachdeuts.ch</span>
        <span className="en">simple german</span>
      </header>
      <MultipleChoice answers={["der","die","das"]}/>
      <footer>
        <div id="footer-content">
          <div>Hello, world!</div>

          <div>&copy; 2020 einfachdeuts.ch</div>
        </div>
      </footer>
    </div>
  );
}

export default App;
